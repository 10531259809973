import React from 'react'
import { Button } from '@chakra-ui/react'
import { NavCard, NavCardCover, NavCardContent, NavCardActions } from '../../../../atoms/NavigationCard'
import { ArrowForwardRounded } from '../../../../atoms/Icon'

const SendGeneralSelector = ({ onClick, recipients, ...rest }) => {
	const path = '/assets/images/graphics/navigation-cards/share-general-card'
	const title = `Share with Friends`
	const description = 'A general link for everyone'

	return (
		<NavCard onClick={onClick} {...rest}>
			<NavCardCover path={path} />
			<NavCardContent title={title} description={description} />
			<NavCardActions>
				<Button aria-label={title} size="md" variant="iconCta" onClick={onClick}>
					<ArrowForwardRounded color="link" />
				</Button>
			</NavCardActions>
		</NavCard>
	)
}

export default SendGeneralSelector
