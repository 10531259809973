import React from 'react'
import { useRSVPs } from '../../../../../hooks/useRSVPs'
import { NavCard, NavCardContent, NavCardCover, NavCardActions, NavCardButton } from '../../../../atoms/NavigationCard'

export const SmartInviteSelector = ({ onClick, ...rest }) => {
	const rsvps = useRSVPs()
	return (
		<>
			{rsvps && rsvps.length > 0 && (
				<NavCard onClick={onClick} {...rest}>
					<NavCardCover path={'/assets/images/graphics/navigation-cards/vidday-invite-list'} />
					<NavCardContent title={'Participant List'} description={'See who participated'} />
					<NavCardActions>
						<NavCardButton />
					</NavCardActions>
				</NavCard>
			)}
		</>
	)
}
