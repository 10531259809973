import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useEventRecipients } from '../../../hooks/useRecipients'
import { Flex, Heading, Text, Image, VStack, useColorMode, HStack } from '@chakra-ui/react'
import { useEventOccasion } from '../../../hooks/useEventOccasion'
import useCoverPhoto from '../../../hooks/useCoverPhoto'
import { useRoleContext } from '../../../contexts/RoleProvider'
import { useEventContext } from '../../../contexts/EventProvider'
import { unArchiveMedia } from '../../../../api/app/media'

const LOGO_COLOR = '/assets/images/vidday_logo.svg'
const LOGO_GRAYSCALE = '/assets/images/vidday_logo_white.svg'

const TheatreHeader = ({ title, description }) => {
	const coverPhoto = useCoverPhoto()
	const { event } = useEventContext()
	const dispatch = useDispatch()
	const { raw, formatted, firstname } = useEventRecipients(event)
	const { label, type } = useEventOccasion()
	const { role } = useRoleContext()
	const { colorMode } = useColorMode()
	const isMemorial = type == 'memorial'

	useEffect(() => {
		if (coverPhoto && coverPhoto.id && coverPhoto.inGlacierStorage && !coverPhoto.isRestoring) {
			dispatch(unArchiveMedia({ id: coverPhoto.id }))
		}
	}, [coverPhoto])

	return (
		<VStack justify="center" position="relative" spacing="0" pt="2rem">
			<Image src="/assets/images/bg/cover-photo-confetti.svg" position="absolute" top="-97px" />
			<Flex mb={['1rem', '0']}>
				<VStack direction="column" justify="center" spacing="0">
					{coverPhoto &&
					!coverPhoto.inGlacierStorage &&
					coverPhoto.signedLocationUrl &&
					role != 'recipient' ? (
						<Image src={coverPhoto?.signedLocationUrl} borderRadius="full" boxSize="68px" mb=".5rem" />
					) : (
						<HStack h="68px">
							<Image
								mx="auto"
								src={colorMode == 'dark' ? LOGO_GRAYSCALE : LOGO_COLOR}
								w="109px"
								h="32px"
								mt={isMemorial ? '1rem' : 0}
								alt="VidDay's Logo"
								mb=".5rem"
							/>
						</HStack>
					)}
					<Heading textAlign="center" as="h2" variant="hero" size="lg" m="0">
						{title ? title : raw.length > 0 ? firstname : formatted}
					</Heading>
					{description && <Text textAlign="center">{description}</Text>}
				</VStack>
			</Flex>
		</VStack>
	)
}

export default TheatreHeader
