import { useSelector, shallowEqual } from 'react-redux'

/**
 * Retrieve the thank you video of an event
 * @returns
 */
export const useThankYouVideo = () => {
	/** Retrieve all medias from the store */
	const video = useSelector((s) => s.event.thankYouVideo, shallowEqual)

	return video
}
