import React from 'react'
import { Button } from '@chakra-ui/react'
import { NavCard, NavCardCover, NavCardContent, NavCardActions } from '../../../../atoms/NavigationCard'
import { ArrowForwardRounded } from '../../../../atoms/Icon'

const SendRecipientSelector = ({ onClick, recipients, ...rest }) => {
	const path = '/assets/images/graphics/navigation-cards/share-recipient-card'
	const title = `Send to ${recipients ? recipients.firstname : 'Recipient'}`
	const description = 'A private link for the recipient'

	return (
		<NavCard onClick={onClick} {...rest}>
			<NavCardCover path={path} />
			<NavCardContent title={title} description={description} />
			<NavCardActions>
				<Button aria-label={title} size="md" variant="iconCta" onClick={onClick}>
					<ArrowForwardRounded color="link" />
				</Button>
			</NavCardActions>
		</NavCard>
	)
}

export default SendRecipientSelector
